<script>

/**
 * Maintenance component
 */
export default {
  data() {
    return {
      maintenance: null
    }
  },
  mounted() {
    let a = window.localStorage.getItem('maintenance')
    if (a) {
      this.maintenance = JSON.parse(a)
    }
  }
};
</script>
<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/">
        <i class="fas fa-home h2"></i>
      </router-link>
    </div>
    <section class="my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="home-wrapper">
              <div class="mb-5">
                <router-link to="/" class="d-block auth-logo">
                  <img
                    src="@/assets/images/logo-350px_red.png"
                    alt=""
                    height="50"
                    class="auth-logo-dark mx-auto"
                  />
                  <img
                    src="@/assets/images/logo-light.png"
                    alt=""
                    height="20"
                    class="auth-logo-light mx-auto"
                  />
                </router-link>
              </div>

              <div class="row justify-content-center">
                <div class="col-sm-4">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/maintenance.svg"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
              <div class="alert alert-danger">
                <h5 v-if="maintenance" v-html="maintenance.desc"></h5>
                <p v-if="maintenance">{{$t('종료시간')}} : {{ new Date(maintenance.untilTime).toLocalTime('YYYY-MM-DD HH:mm:ss') }}</p>
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
